<template>
  <div class="min-h-screen w-full text-white" :style="{
    backgroundColor: page?.theme?.color || '#000',
    backgroundImage: page?.theme?.backgroundImage
      ? `url(${page.theme.backgroundImage})`
      : '',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }">
    <!-- ✅ Desktop Content Overlay -->
    <div v-if="activeDesktopContent" class="fixed inset-0 z-[60] hidden md:flex items-center justify-center"
      style="background: black">
      <img :src="activeDesktopContent.imageUrl" class="absolute inset-0 w-full h-full object-cover"
        alt="Desktop Background" />
      <div class="relative z-10 text-white text-3xl font-bold text-center drop-shadow-md">
        {{ activeDesktopContent.label }}
      </div>
    </div>

    <!-- ✅ Wrapper สำหรับ salepageContents (ทุกขนาด ถ้าไม่มี desktop mode) -->
    <div v-if="!activeDesktopContent || isMobile"
      class="max-w-[600px] mx-auto px-4 py-10 backdrop-blur-sm rounded-xl shadow-lg">
      <div v-for="item in sortedContents" :key="item._id" class="mb-4"
        v-show="item.active !== false && item.status !== 'inactive'">
        <div v-if="item._type === 'button'" role="button" tabindex="0" @click="handleClick(item)"
          class="cursor-pointer flex items-center justify-center gap-3 w-full text-center py-4 rounded-2xl font-semibold shadow-md text-2xl sm:text-2xl md:text-2xl shake"
          :style="{
            backgroundColor: item.color,
            border: `2px solid ${item.ButtonborderColor || 'transparent'}`,
            color: item.ButtontextColor || 'white',
          }">
          <img v-if="item.icon" :src="item.icon" alt="icon" class="max-h-10 h-10 w-auto object-contain" />
          <span>{{ item.label }}</span>
        </div>

        <div v-else-if="item._type === 'text'"
          class="w-full px-4 py-2 text-center whitespace-pre-line text-2xl sm:text-xl md:text-2xl neon-text" :style="{
            color: item.textColor || 'white',
          }">
          {{ item.content }}
        </div>

        <div v-else-if="item._type === 'image'" role="button" tabindex="0" class="block cursor-pointer"
          @click="handleClick(firstButton)">
          <img :src="item.url" class="w-full rounded-lg neon-border"
            :style="firstButton ? generateNeonStyle(firstButton.color) : {}" />
        </div>

        <div v-else-if="item._type === 'game'" class="rounded-lg overflow-hidden neon-border"
          :style="firstButton ? generateNeonStyle(firstButton.color) : {}">
          <iframe :src="item.url" allowfullscreen class="w-full" style="aspect-ratio: 9 / 16; border: none"></iframe>
        </div>
      </div>

      <div v-if="popupToShow" class="mt-6">
        <p class="text-lg font-bold mb-2">💬 Popups</p>
        <div class="text-sm mb-4">
          <p>Effect: {{ popupToShow.effect }}</p>
          <img :src="popupToShow.imageUrl" class="rounded-lg shadow-md mb-2 w-full neon-border"
            :style="firstButton ? generateNeonStyle(firstButton.color) : {}" />
          <a :href="popupToShow.link" class="text-blue-400 text-xs break-words">
            {{ popupToShow.link }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="popupToShow" class="fixed inset-0 bg-black/80 z-50 flex items-center justify-center animate-fadein">
      <div class="relative w-[90%] max-w-sm rounded-lg">
        <!-- ✅ คลิกที่รูปอย่างเดียว -->
        <img :src="popupToShow.imageUrl" class="rounded-lg shadow-xl w-full neon-border cursor-pointer"
          :style="firstButton ? generateNeonStyle(firstButton.color) : {}" alt="Popup"
          @click="handleClick({ ...popupToShow, url: popupToShow.link })" />

        <!-- ✅ ปุ่ม CTA -->
        <a v-if="firstButton && firstButton.label"
          class="mt-4 flex items-center justify-center gap-2 text-center text-white py-3 rounded-md font-semibold shadow-md shake"
          :style="{
            backgroundColor: firstButton.color || '#1e40af',
            color: firstButton.ButtontextColor || 'white',
            border: `2px solid ${firstButton.ButtonborderColor || 'transparent'}`
          }" @click.prevent="handleClick(firstButton)" role="button">
          <img v-if="firstButton.icon" :src="firstButton.icon" alt="icon" class="h-6 w-auto object-contain" />
          <span>{{ firstButton.label }}</span>
        </a>
      </div>
    </div>

  </div>
</template>

<script setup>
/* global fbq */
import { ref, onMounted, computed, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
import api from '@/utils/axios'
import { API_PATHS } from '@/utils/apiPath'

const route = useRoute()
const slug = computed(() => route.params.slug)
const page = ref(null)
const popups = ref([])
const popupToShow = ref(null)
const isMobile = ref(window.innerWidth < 768)


const insertFacebookPixelScript = (pixelId) => {
  if (document.getElementById(`fb-pixel-${pixelId}`)) return

  const script = document.createElement('script')
  script.id = `fb-pixel-${pixelId}`
  script.innerHTML = `
    !function(f,b,e,v,n,t,s){
      if(f.fbq)return;n=f.fbq=function(){
        n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)
      };
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${pixelId}');
    fbq('track', 'PageView');
  `
  document.head.appendChild(script)

  const noscript = document.createElement('noscript')
  noscript.innerHTML = `
    <img height="1" width="1" style="display:none"
    src="https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1"/>
  `
  document.body.appendChild(noscript)
}

const firstButton = computed(() =>
  sortedContents.value.find(item =>
    item._type === 'button' &&
    item.active !== false &&
    item.status !== 'inactive' &&
    item.label // ✅ ต้องมี label ด้วย
  )
)


const activeDesktopContent = computed(() => {
  const items = page.value?.desktopContents || []
  return items.find(item => item.status === 'active') || null
})


const updateIsMobile = () => {
  isMobile.value = window.innerWidth < 768
}

const generateNeonStyle = (color) => {
  if (!color) return {}
  return {
    border: `1px solid ${color}`,
    boxShadow: `0 0 2px ${color}, 0 0 5px ${color}, 0 0 10px ${color}`
  }
}

const fetchSalePage = async () => {
  try {
    const res = await api.get(API_PATHS.GET_SALEPAGE_BY_SLUG(slug.value))
    page.value = res.data
    // console.log('📦 FULL API RESPONSE:', res)
    popups.value = res.data?.popups || []
    await api.post(API_PATHS.TRACK_VIEW(page.value.tenant))

    if (page.value?.fbpixel?.ids?.length) {
      for (const px of page.value.fbpixel.ids) {
        if (px.id) insertFacebookPixelScript(px.id)
      }
    }

    const firstPopup = popups.value.find(p => p?.status?.toLowerCase() !== 'inactive' && Number(p.effect) > 0)
    if (firstPopup) {
      setTimeout(() => {
        popupToShow.value = firstPopup
      }, firstPopup.effect * 60 * 1000)
    }
  } catch (err) {
    console.error('Failed to load page', err)
  }
}

const fetchSalePageDetail = async () => {
  try {
    const res = await api.get(API_PATHS.GET_SALEPAGE_BY_SLUG(slug.value))
    const pageData = res.data

    // ✅ SET <title>
    if (pageData?.title) {
      document.title = pageData.title
    }

    // ✅ SET <favicon>
    if (pageData?.favicon) {
      const existing = document.querySelector("link[rel~='icon']")
      if (existing) {
        existing.href = pageData.favicon
      } else {
        const icon = document.createElement('link')
        icon.rel = 'icon'
        icon.href = pageData.favicon
        document.head.appendChild(icon)
      }
    }
  } catch (err) {
    console.error('❌ Failed to set <title> / <favicon>:', err)
  }
}



onMounted(() => {
  updateIsMobile()
  window.addEventListener('resize', updateIsMobile)
  fetchSalePageDetail()
  fetchSalePage() // <-- อันนี้ดึง content ปกติ
})


onUnmounted(() => {
  window.removeEventListener('resize', updateIsMobile)
})

const sortedContents = computed(() =>
  [...(page.value?.salepageContents || [])]
    .filter(item => item.active !== false)
    .sort((a, b) => a.order - b.order)
)

const handleClick = (item) => {
  // ✅ เปิดลิงก์ทันที เพื่อให้ browser อนุญาต (ไม่โดนบล็อค)
  const win = window.open('', '_blank');

  // 🔄 Track click async แต่เปิดลิงก์ไว้ล่วงหน้า
  api.post(API_PATHS.TRACK_CLICK(page.value.tenant), {
    contentId: item._id,
    label: item.label,
    type: item._type,
  }).catch((err) => console.warn('Track click error', err));

  if (window?.fbq) {
    fbq('trackCustom', 'ClickButton', {
      label: item.label,
      content_id: item._id,
    });
  }

  // ✅ เปลี่ยน URL ของหน้าต่างที่เปิดไว้
  if (item?.url && win) {
    win.location.href = item.url;
  }
};




</script>

<script>
export default {
  name: 'HomeView'
}
</script>


<style scoped>
.neon-border {
  border-radius: 15px;
  background-color: #111;
}

.animate-fadein {
  animation: fadein 0.5s ease-out;
}

.neon-text {
  font-weight: bold;
  text-shadow: 0 0 1px currentColor, 0 0 2px currentColor, 0 0 3px currentColor;
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: scale(0.95);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes zoomPulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.02);
  }
}

.shake {
  animation: zoomPulse 1.2s ease-in-out infinite;
}
</style>
